import React, { Component } from "react";

import * as formStyles from "./form.module.scss";
import { joinClasses } from "../../utils";

class ContactForm extends Component {
  state = {
    form: {
      name: "",
      email: "",
      message: "",
      _subject: `Sydney SG - Contact Submission ${new Date()}`,
    },
    submitted: false,
    pending: false,
    error: false,
    success: false,
    formClasses: [formStyles.contactForm],
  };

  emailRef = React.createRef();

  changeHandler = (event) => {
    let prevForm = this.state.form;
    let name = event.target.name;
    let value = event.target.value;
    this.setState({
      form: {
        ...prevForm,
        [name]: value,
      },
    });
    if (event.target === this.emailRef.current) {
      this.setState({
        error: false,
      });
    }
  };

  submissionHandler = (e) => {
    e.preventDefault();
    this.setState({ pending: true });
    fetch("https://formspree.io/f/xvoypkon", {
      method: "POST",
      body: JSON.stringify(this.state.form),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.status === 200) {
        this.setState((prevState, props) => {
          return {
            pending: false,
            submitted: true,
            success: true,
            error: false,
            formClasses: [
              formStyles.contactForm,
              formStyles.contactForm__submitted,
              formStyles.contactForm__success,
            ],
          };
        });
      } else {
        this.setState({
          pending: false,
          submitted: true,
          error: true,
          success: false,
        });
      }
    });
  };

  buttonClickHandler = (e) => {
    this.setState((state, props) => {
      return {
        formClasses: [
          formStyles.contactForm,
          formStyles.contactForm__submitted,
        ],
      };
    });
  };

  render() {
    let email = (
      <input
        ref={this.emailRef}
        type="email"
        required
        autoComplete="off"
        id="email"
        className={joinClasses(
          formStyles.formGroup__email,
          this.state.submitted && this.state.error
            ? formStyles.formGroup__email__invalid
            : null
        )}
        name="email"
        value={this.state.form.email}
        onChange={this.changeHandler}
        placeholder="Email"
      />
    );

    let pendingMask = (
      <div className={formStyles.pendingMaskContainer__hidden}></div>
    );

    if (this.state.pending) {
      pendingMask = (
        <div className={formStyles.pendingMaskContainer}>
          <div className={formStyles.pendingAnimation}>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      );
    }

    let errorMessage = this.state.error ? (
      <p className={formStyles.errorMessage}>
        Your message didn't go through! <br />
        Check your email address. <br />
        If that doesn't fix it, just email me at hello@sydneysg.com.
      </p>
    ) : (
      false
    );

    let form = (
      <form
        id="contactForm"
        autoComplete="off"
        onSubmit={this.submissionHandler}
        className={joinClasses(this.state.formClasses)}
      >
        <div className={formStyles.formGroupContainer}>
          <div className={formStyles.formGroup}>
            <input
              required
              autoComplete="off"
              type="text"
              id="name"
              className={formStyles.formGroup__name}
              name="name"
              value={this.state.form.name}
              onChange={this.changeHandler}
              placeholder="Name"
            />
            {/* <label htmlFor="name" className={formStyles.formGroup__label}>
              Name
            </label> */}
          </div>
          <div className={formStyles.formGroup}>
            {email}
            {/* <label htmlFor="email" className={formStyles.formGroup__label}>
              Email
            </label> */}
          </div>
        </div>
        <div
          className={[formStyles.formGroup, formStyles.formGroup__message].join(
            " "
          )}
        >
          <textarea
            autoComplete="off"
            required
            type="message"
            id="message"
            className={formStyles.formGroup__message__box}
            name="message"
            value={this.state.form.message}
            onChange={this.changeHandler}
            placeholder="Message"
          />
          {/* <label htmlFor="message" className={formStyles.formGroup__label}>
              Message
            </label> */}
        </div>
        <button
          className={formStyles.button}
          type="submit"
          onClick={this.buttonClickHandler}
        >
          <span className={formStyles.button__label}>Submit</span>
        </button>
        {errorMessage}
        {pendingMask}
      </form>
    );

    return <div className={formStyles.formContainer}>{form}</div>;
  }
}

export default ContactForm;
