import React from "react";

import ContactForm from "./../components/contact/form/form";
import ContactInfo from "./../components/contact/contactInfo/contactInfo";
// import PageHeader from "./../components/hoc/pageHeader/pageHeader";
// import Instagram from './../components/contact/instagram/instagram';
import OverflowWrap from "./../components/hoc/overflowWrap";

import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import FlexCenter from "../components/hoc/flexCenter/flexCenter";

const Contact = (props) => {
  return (
    <Layout>
      <SEO title="Contact Sydney SG" />
      <OverflowWrap>
        <FlexCenter>
          <ContactForm />
          <ContactInfo />
          {/* <Instagram /> */}
        </FlexCenter>
      </OverflowWrap>
    </Layout>
  );
};

export default Contact;
