import React from "react";

const overflowWrap = (props) => {
  return (
    <div style={{ height: "100%", width: "100%", overflow: "auto" }}>
      {props.children}
    </div>
  );
};

export default overflowWrap;
