// extracted by mini-css-extract-plugin
export var br = "contactInfo-module--br--0ab6c";
export var fadeInVisibility = "contactInfo-module--fadeInVisibility--0c6f3";
export var fadeOutVisibility = "contactInfo-module--fadeOutVisibility--bbcd1";
export var hideNav = "contactInfo-module--hideNav--9e5e5";
export var image = "contactInfo-module--image--37dac";
export var infoContainer = "contactInfo-module--infoContainer--b1b6f";
export var infoContainer__instagram = "contactInfo-module--infoContainer__instagram--09f15";
export var infoContainer__instagram__img = "contactInfo-module--infoContainer__instagram__img--a6e68";
export var invalid = "contactInfo-module--invalid--a1fdf";
export var photoFadeIn = "contactInfo-module--photoFadeIn--58658";
export var shiftKeyPush = "contactInfo-module--shiftKeyPush--e7090";
export var text = "contactInfo-module--text--e16f0";