import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import * as contactStyles from "./contactInfo.module.scss";

const ContactInfo = () => {
  const data = useStaticQuery(graphql`
    query SocialImages {
      allContentfulSocialImage {
        edges {
          node {
            file {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 300
                    jpgOptions: { quality: 85 }
                    webpOptions: { quality: 90 }
                  )
                }
              }
            }
            id
            altText
          }
        }
      }
    }
  `);
  const imageData =
    data.allContentfulSocialImage.edges[0].node.file.localFile.childImageSharp
      .gatsbyImageData;
  const altText = data.allContentfulSocialImage.edges[0].node.altText;
  return (
    <div className={contactStyles.infoContainer}>
      <div className={contactStyles.text}>
        <p>
          Feel free to reach out with questions about availability and pricing,
          or just to say hi.
        </p>
        <a href={"mailto: hello@sydneysg.com"} target={"_blank"}>
          hello@sydneysg.com
        </a>
      </div>

      <GatsbyImage
        image={imageData}
        alt={altText}
        className={contactStyles.image}
        objectFit="contain"
        width={300}
        imgStyle={{
          width: "auto",
          objectFit: "contain",
        }}
        placeholder="none"
      />
    </div>
  );
};

export default ContactInfo;
